.header {
  padding: 10px;
  border-bottom: 1px solid #000;
  background-color: #00142a;
  color: #fff;
  padding-top: 10px;
  display: flex !important;
  flex-direction: column;
}

.row {
  padding: 10px;
  border-top: 1px solid #000;
  border-right: 1px solid #000;
  position: relative;
}

.filter {
  padding: 10px;
  border-bottom: 1px solid #000;
  width: auto;
}

.grid > div {
  border-bottom: 1px solid #000;
}

.grid {
  overflow-x: hidden !important;
  overflow-y: auto !important;
}