.group:before,
.group:after {
    content: "";
    display: table;
}
.group:after {
    clear: both;
}
.group {
    zoom: 1;
    padding-left: 13px;
}

.tabColor {
  background-color: #412920;
}

.cancelledColor {
  background-color: darkred !important;
}

.tabs { 
  list-style: none; 
  margin: 0;
  background-color: #412920;
}
.tabs li { 
  /* Makes a horizontal row */
  float: left; 
  cursor: pointer;
  
  /* So the psueudo elements can be
     abs. positioned inside */
  position: relative; 
}
.tabs span { 
  /* Make them block level
     and only as wide as they need */
  float: left; 
  padding: 10px 17px; 
  text-decoration: none;
  
  /* Default colors */ 
  color: black;
  background: #ddc385; 
  
  /* Only round the top corners */
  -webkit-border-top-left-radius: 15px;
  -webkit-border-top-right-radius: 15px;
  -moz-border-radius-topleft: 15px;
  -moz-border-radius-topright: 15px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px; 
}
.tabs .active {
  /* Highest, active tab is on top */
  z-index: 3;
}
.tabs .active span { 
  /* Colors when tab is active */
  background: white; 
  color: black; 
}

.tabs .assignment {
  z-index: 100;
}

.tabs .assignment span { 
  /* Colors when tab is assigned */
  background: #8effa2; 
  color: black; 
}

.tabs li:before, .tabs li:after, 
.tabs li span:before, .tabs li span:after {
  /* All pseudo elements are 
     abs. positioned and on bottom */
  position: absolute;
  bottom: 0;
}
/* Only the first, last, and active
   tabs need pseudo elements at all */
.tabs li:last-child:after,   .tabs li:last-child span:after,
.tabs li:first-child:before, .tabs li:first-child span:before,
.tabs .active:after,   .tabs .active:before, 
.tabs .active span:after, .tabs .active span:before {
  content: "";
}
.tabs .active:before, .tabs .active:after {
  background: white; 
  
  /* Squares below circles */
  z-index: 1;
}
/* Squares */
.tabs li:before, .tabs li:after {
  background: #ddc385;
  width: 10px;
  height: 10px;
}
.tabs li:before {
  left: -10px;      
}
.tabs li:after { 
  right: -10px;
}
/* Circles */
.tabs li span:after, .tabs li span:before {
  width: 20px; 
  height: 20px;
  /* Circles are circular */
  -webkit-border-radius: 10px;
  -moz-border-radius:    10px;
  border-radius:         10px;
  background: #412920;
  
  /* Circles over squares */
  z-index: 2;
}
.cancelledColor li span:after, .cancelledColor li span:before {
  width: 20px; 
  height: 20px;
  /* Circles are circular */
  -webkit-border-radius: 10px;
  -moz-border-radius:    10px;
  border-radius:         10px;
  background: darkred;
  
  /* Circles over squares */
  z-index: 2;
}
.tabs .active span:after, .tabs .active span:before {
  background: #ddc385;
}
/* First and last tabs have different
   outside color needs */
.tabs li:first-child.active span:before,
.tabs li:last-child.active span:after {
  background: #412920;
}
.cancelledColor li:first-child.active span:before,
.cancelledColor li:last-child.active span:after {
  background: darkred;
}
.tabs li span:before {
  left: -20px;
}
.tabs li span:after {
  right: -20px;
}

.dropdown {
  display: none;
}

@media only screen and (max-width: 1250px) {
  .tabs {
    display: none;
  }
  .dropdown {
    display: block;
    margin-top: 10px;
  }
  .dropdown label, .dropdown label:focus {
    color: #fff !important;
  }
  .dropdown .MuiSelect-select {
    color: #fff !important;
  }
  .dropdown .MuiSelect-select:focus {
    background-color: #412920 !important;
  }
}