.imageRotateHorizontal{
  -moz-animation: spinHorizontal 2s infinite linear;
  -o-animation: spinHorizontal 2s infinite linear;    
  -webkit-animation: spinHorizontal 2s infinite linear;
  animation: spinHorizontal 2s infinite linear;
}

@-moz-keyframes spinHorizontal {
  0% {
      -moz-transform: rotateY(0deg);
  }

  100% {
      -moz-transform: rotateY(360deg);
  }
}

@keyframes spinHorizontal {
0% { 
      transform: rotateY(0deg); 
}
  100% {
      transform: rotateY(360deg);
  }
}


@-ms-keyframes spinHorizontal {
0% { 
      -ms-transform: rotateY(0deg); 
}
  100% {
      -ms-transform: rotateY(360deg);
  }
}

@-o-keyframes spinHorizontal {
0% { 
      -o-transform: rotateY(0deg); 
}
100% { 
      -o-transform: rotateY(360deg); 
}
}

@-webkit-keyframes spinHorizontal {
0% { 
      -webkit-transform: rotateY(0deg); 
}
100% { 
      -webkit-transform: rotateY(360deg); 
}
}