@media print {
  .priceGridMediaPrint {
    margin: none !important;
    overflow: visible !important;
    display: block !important;
    height: 6000px !important;
    break-inside: avoid !important;
    width: 50000px !important;
    padding: 0 !important;
  }

  .suiteCard {
    height: 80px !important;
    font-size: 7px !important;
    color: #000 !important;
    z-index: 10000 !important;
    box-shadow: none !important;
    print-color-adjust: exact !important;
    -webkit-print-color-adjust: exact !important;
  }

  .unitSuiteCard {
    height: 80px !important;
    font-size: 8px !important;
    border: none !important;
    box-shadow: none !important;
    print-color-adjust: exact !important;
    -webkit-print-color-adjust: exact !important;
  }

  .borderBox {
    height: 10px !important;
  }

  .borderBottomBox {
    height: 20px !important;
    border-color: #000 !important;
    font-size: 3px !important;
  }

  .input {
    display: none !important;
  }

  .level {
    color: #000 !important;
  }
  .custom {
    color: #000 !important;
  }
  .textInput {
    color: #000 !important;
  }
  .titleContainer {
    display: none !important;
  }

  .grow {
    display: none !important;
  }
  .suiteCard {
    height: 100px !important;
    font-size: 6px !important;
  }
  .suiteHeight {
    height: 200px !important;
  }
  .grid-card {
    height: 90px !important;
  }
  .unit-price {
    height: 12px !important;
  }
  .unit-allocation-container {
    height: 7px !important;
    padding: 0px !important;
    top: 0px !important;
  }
  .unit-allocation {
    font-size: 5px;
    height: 7px;
    line-height: 10px !important;
  }
}